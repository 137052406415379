import { init, getCurrentScope, browserTracingIntegration, addBreadcrumb } from '@sentry/vue';

// import { CaptureConsole } from '@sentry/integrations';
import { config } from '@/shared/lib/config/config';

if (process.env.NODE_ENV === 'production') {
  const isStand = window.APP_VERSION.split('-').length > 1;
  const [standName] = location.host.split('.');

  window.Sentry = {
    init(app, options) {
      init({
        app,
        attachProps: true,
        logErrors: true,
        dsn: 'https://02d2a1bb2595438197718fe5c9aa8ccb@sentry-new.huntflow.ru/9',
        environment: isStand ? 'stand' : 'prod',
        release: window.APP_VERSION,
        tracesSampleRate: isStand ? 0 : 0.5,
        integrations: [
          // Отключили, т.к. сильно загаживает
          // ToDo: https://huntflow.atlassian.net/browse/DEV-14244
          // new CaptureConsole({
          //   levels: ['error']
          // }),
          browserTracingIntegration({
            router: options.router
          })
        ],
        beforeSend: (event) => {
          const route = options.router ? options.router.currentRoute.value : options.route;

          const attributes: Record<string, unknown> = {};

          const params = route.params || {};
          for (const key of Object.keys(params)) {
            attributes[`params.${key}`] = params[key];
          }
          const query = route.query || {};
          for (const key of Object.keys(query)) {
            const value = query[key];
            if (value) {
              attributes[`query.${key}`] = value;
            }
          }

          return {
            ...event,
            extra: {
              ...event.extra,
              'vue.route': attributes
            },
            tags: {
              ...event.tags,
              routeName: route.name,
              url: window.location.href // по-дефолту в url не попадает хэш, когда переведём на роутинг без хэша - дропнуть
            }
          };
        },
        initialScope: (scope) => {
          if (isStand) {
            scope.setTag('stand_name', standName);
          }
          scope.setTag('host', window.location.host);
          return scope;
        },
        beforeBreadcrumb(breadcrumb, hint) {
          if (breadcrumb.category === 'xhr' && hint) {
            const { xhr, input } = hint as {
              xhr: XMLHttpRequest;
              input: string | null;
            };

            addBreadcrumb({
              type: 'query',
              category: 'xhr payload',
              level: 'info',
              message: `${breadcrumb.data?.url} payload`,
              data: {
                request: input,
                response: breadcrumb.data?.status_code >= 500 ? undefined : xhr.response
              }
            });
          }
          return breadcrumb;
        }
      });

      // Эта задежка нужна потому что необходимые переменные могут объявляться
      // позже подключения скрипта
      setTimeout(() => {
        const scope = getCurrentScope();
        const account = config.appConfig.get('account');
        if (account) {
          scope.setUser({
            id: String(account.id),
            email: account.email,
            org: account.nick
          });
          scope.setTag('organization', account.nick);
        }
      }, 500);
    }
  };
}
